<template>
  <div>
    <form @submit.stop.prevent="onSubmit" class="form">
     <div class="card-body">
       <p v-if="msgs.length">
         <b>Status:</b>
         <ul>
           <li v-for="error in msgs" :key="error">{{ error }}</li>
         </ul>
       </p>
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Informações Básicas:</h3>
      <div class="mb-15">
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Nome: </label>
        <div class="col-lg-6">
         <input v-model="con_name" required type="text" class="form-control" placeholder="Insira o nome completo"/>
         <span class="form-text text-muted">Por favor insira o nome de cadastro</span>
        </div>
       </div>
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Nome Empresa: </label>
        <div class="col-lg-6">
         <input v-model="con_company_name" required type="text" class="form-control" placeholder="Insira o nome completo"/>
         <span class="form-text text-muted">Por favor insira o nome da empresa</span>
        </div>
       </div>
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">CNPJ: </label>
        <div class="col-lg-6">
         <input v-model="con_document" required type="text" class="form-control" placeholder="Insira o CNPJ"/>
         <span class="form-text text-muted">Por favor insira o CNPJ da empresa</span>
        </div>
       </div>
      </div>
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Endereço:</h3>
      <div class="mb-15">
        <div class="form-group row">
         <label class="col-lg-2 col-form-label">Endereço Postal:</label>
         <div class="col-lg-4">
          <input v-model="con_zipcode" required type="cep" class="form-control" placeholder="CEP"/>
          <span class="form-text text-muted">Preencha o Endereço Postal</span>
         </div>
         <b-button @click="preenche_cep">Pesquisar</b-button>
        </div>
        <div class="form-group row">
         <label class="col-lg-1 col-form-label">Logradouro:</label>
         <div class="col-lg-2">
          <input v-model="con_logradouro" type="address" class="form-control" placeholder="Logradouro"/>
          <span class="form-text text-muted">Logradouro</span>
         </div>
         <label class="col-lg-1 col-form-label">Cidade:</label>
         <div class="col-lg-2">
          <input v-model="con_city" required type="cep" class="form-control" placeholder="Cidade"/>
          <span class="form-text text-muted">Preencha a Cidade</span>
         </div>
         <label class="col-lg-1 col-form-label">UF:</label>
         <div class="col-lg-2">
          <input v-model="con_uf" required type="uf" class="form-control" placeholder="UF"/>
          <span class="form-text text-muted">Preencha a Cidade</span>
         </div>
        </div>
      </div>

      <!--<h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Dados da Conta:</h3>
      <div class="mb-3">
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Contato</label>
        <div class="col-lg-6">
         <div class="input-group">
          <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
          <input v-model="telefone" required type="text" class="form-control" placeholder="Número Telefone"/>
         </div>
       </div>
       </div>
      </div>-->

     </div>
     <div class="card-footer">
      <div class="row">
       <div class="col-lg-3"></div>
       <div class="col-lg-6">
         <b-button v-if="$route.name == router_actions.route_new" type="submit" class="btn btn-success mr-2">Cadastrar</b-button>
         <b-button v-if="$route.name == router_actions.route_edit" type="submit" class="btn btn-success mr-2">Atualizar</b-button>
         <b-button type="reset" class="btn btn-secondary">Cancelar</b-button>
       </div>
      </div>
     </div>
    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import ApiService from "@/core/services/api.service";
export default {
  name: "Company",
  props: {
    id: String,
    router_actions: Object
  },
  mounted() {

    if(this.$route.name == this.router_actions.route_edit){
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listar Companias", route: '/Companies/list' },
        { title: "Editar Empresa", route: '/Companies/edit/'+this.$route.params.id }
      ]);
      let id = this.$route.params.id;
      ApiService.get('companies/'+id)
        .then(({data}) =>{
          this.msgs.push(data.message);
          this.con_name = data.companies[0].con_name;
          this.con_company_name = data.companies[0].con_company_name;
          this.con_document = data.companies[0].con_document;
          this.con_city = data.companies[0].con_city;
          this.con_uf = data.companies[0].con_uf;
          this.con_zipcode = data.companies[0].con_zipcode;
        })
          .catch(({response}) => {
            this.msgs.push(response.data.errors);
          });
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listar Companias", route: '/Companies/list' },
        { title: "Cadastrar Empresa", route: '/Companies/new/' }
      ]);
      this.con_name = '';
      this.con_company_name = '';
      this.con_document = '';
      this.con_city = '';
      this.con_uf = '';
      this.con_logradouro = '';
      this.con_zipcode = '';
    }
  },
  components: {},
  data() {
    return {
      con_name: '',
      con_zipcode: '',
      con_logradouro: '',
      con_city: '',
      con_uf: '',
      con_company_name: '',
      con_document: '',
      msgs: []
    };
  },
  methods: {
    preenche_cep(){
      let con_zipcode = this.con_zipcode;
      this.con_zipcode = 'carregando...';

      ApiService.get('Etc/ConsultaCep/' + con_zipcode)
        .then(response => {
          this.con_zipcode = response.data.infos.cep;
          this.con_logradouro = response.data.infos.logradouro
          this.con_city = response.data.infos.localidade;
          this.con_uf = response.data.infos.uf;

        });
    },
    onSubmit(e) {
      let id = null, rota = null, metodo = null;

      if(this.msgs.length){
        this.msgs = [];
      }

      this.con_zipcode = String(this.con_zipcode).replace(/\D/g, "");

      let company = {
        con_name: this.con_name,
        con_zipcode: this.con_zipcode,
        con_city: this.con_city,
        con_uf: this.con_uf,
        con_company_name: this.con_company_name,
        con_document: this.con_document
      };

      if(this.$route.name == this.router_actions.route_edit){
        id = this.$route.params.id;
        company.id = id;
        rota = id;
        metodo = 'put';
      }else{
        metodo = 'post';
        rota = 'add';
      }

      ApiService[metodo]('companies/'+rota, company)
        .then(({data}) => {
          this.msgs.push(data.message);
          this.$router.push({ name: "companies-list" })
        })
          .catch(({response}) => {
            this.msgs.push(response.data.errors);
          });

      e.preventDefault();
    }
  },
};
</script>
